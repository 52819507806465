import * as z from 'zod'

export const EnvironmentSchema = z.enum([
  'development',
  'preview',
  'production',
  'test',
  'local',
])

/** @type {z.infer<typeof EnvironmentSchema>} */
export const PURPOSITY_ENV =
  process.env.VERCEL_ENV ||
  process.env.NEXT_PUBLIC_VERCEL_ENV ||
  (process.env.NODE_ENV === 'production' ? 'local' : process.env.NODE_ENV) ||
  'development'

export const WEBHOOK_SECRET_HEADER_KEY = 'x-hasura-webhook-secret'

export const SENTRY_DSN =
  'https://0a571a9bb71a4200931ed42e7a04923a@o546790.ingest.sentry.io/4504234316988416'
